// customFetch.js
export default async function customFetch(url, options) {
  const response = await fetch(url, options);
  if (response.status === 401) {
    // Remove cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Redirect to login
    window.location.href = "/";
  } else {
    return response;
  }
}
