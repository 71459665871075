import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./dragDrop.css";

import AWS from 'aws-sdk';
const {BUCKET_NAME, AWS_ID, AWS_SECRET} = require('../../config/AWS')

const s3 = new AWS.S3({
    accessKeyId: AWS_ID,
    secretAccessKey: AWS_SECRET,
    signatureVersion: 'v4',
    region: 'eu-central-1'
});

const getSignedUrl = (file, user) => {
    return s3.getSignedUrl('putObject', {
        Bucket: BUCKET_NAME,
        Key: `${user._id}/${file.path}`,
        Expires: 100
    })
}

const FileUpload = ({ user, setFilenames, setUser }) => {
    const [files, setFiles] = useState([]);

    const onDrop = useCallback(async (acceptedFiles) => {
        setFiles([...files, ...acceptedFiles]);
        for (let file of acceptedFiles) {
            try {
                const signedUrl = getSignedUrl(file, user);
                await fetch(signedUrl, {
                    method: "PUT",
                    body: file,
                });
                let existingFiles = user.collaborationInfo?.uploadedFiles &&  user.collaborationInfo?.uploadedFiles.length > 0 ? user.collaborationInfo?.uploadedFiles : []
                const newUploadedFiles = [
                    ...existingFiles,
                    { name: file.name, url: signedUrl.replace(/\?.+/g, "") },
                ];
                setUser({
                    ...user,
                    collaborationInfo: {
                        ...user.collaborationInfo,
                        uploadedFiles: newUploadedFiles,
                    },
                });
            } catch (err) {
                console.error(err);
            }
        }
    }, [user, setUser, files]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*, application/pdf",
        maxSize: 10 * 1024 * 1024,
        onDrop,
    });

    const handleRemoveFile = (fileToRemove) => {
        setFiles(files.filter((file) => file !== fileToRemove));
        const newUploadedFiles = user.collaborationInfo.uploadedFiles.filter(
            (file) => file.name !== fileToRemove.name
        );
        setUser({
            ...user,
            collaborationInfo: {
                ...user.collaborationInfo,
                uploadedFiles: newUploadedFiles,
            },
        });
    };

    return (
        <>
            <div className="root" {...getRootProps()}>
                <input {...getInputProps()} />
                <h4>Media Kits and other assets</h4>
                <p className="drag-drop-box">Drag and drop your files here (max 10 MB)</p>
            </div>
            <aside className="sidebar">
                <h4>Uploaded Data</h4>
                <ul className="uploaded-files-list">
                    {user.collaborationInfo?.uploadedFiles?.map((file, index) => (
                        <li key={index}>
                            <span>{file.name}</span>
                            <button
                                className="remove-file-btn"
                                onClick={() => handleRemoveFile(file)}
                                type="button"
                            >
                                Remove
                            </button>
                        </li>
                    ))}
                </ul>
            </aside>
        </>
    );
};

export default FileUpload;
