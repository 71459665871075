export default ['',
'de',
'en',
'es',
'fr',
'pt',
'it',
'da',
'ru',
'lv',
'cz',
'pl',
'ar',
'bg',
'nl',
'fi',
'ja',
'hu',
'no',
'el',
'ro',
'tr',
'li']