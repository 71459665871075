import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, Dropdown, Spinner } from 'react-bootstrap';
import { SERVERURL } from '../config/VARS';
import infludataLogo from './infludata-logo.png'; // Make sure to import the logo
import './Hello.css';
import customFetch from './customFetch';



async function getUsernameSuggestions(letters, platform) {
    if (letters.length < 3) {
        return []
    }
    let response = await customFetch(`${SERVERURL}getUserSuggestionByLetters?letters=${letters}&index=${platform}`, {
        headers: {
            accept: "application/json, text/plain, */*"
        },
        method: "GET",
        credentials: "include"
    })
    let data = await response.json()
    console.log(data)
    return data;
}

function Hello() {
    const [username, setUsername] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [platform, setPlatform] = useState('instagram');
    const [loading, setLoading] = useState(false);
    const [creators, setCreators] = useState([]);

    useEffect(() => {
    async function fetchCreators() {
        try {
        let response = await customFetch(`${SERVERURL}getEditedCustomUsers`, {
            headers: {
            accept: "application/json, text/plain, */*"
            },
            method: "GET",
            credentials: "include"
        });
        let data = await response.json();
        setCreators(data);
        } catch (error) {
        console.error(error);
        setCreators([]);
        }
    }

    fetchCreators();
    }, []);
  

    useEffect(() => {
        if (username.length >= 1) {
          setLoading(true);
          getUsernameSuggestions(username, platform).then((data) => {
            setSuggestions(data);
            setLoading(false);
          });
        }
      }, [username, platform]);

    const handleChange = (event) => {
        setUsername(event.target.value);
        setShowSuggestions(true);
    }

    const handlePlatformClick = (event) => {
        setPlatform(event.target.value);
      };

      return (
        <div className="hello-container">
          <header>
            <img src={infludataLogo} alt="Infludata Logo" className="infludata-logo" />
            <h1 className="header-title">influData Creator Management</h1>
          </header>
          <div className="d-flex justify-content-center align-items-center">
            <Form className="text-center">
                <div className="platform-buttons-wrapper">
                    <div className="platform-buttons mb-3">
                        <Button onClick={handlePlatformClick} value="instagram" variant={platform === 'instagram' ? 'instagram' : 'outline-instagram'}>Instagram</Button>
                        <Button onClick={handlePlatformClick} value="tiktok" variant={platform === 'tiktok' ? 'tiktok' : 'outline-tiktok'}>TikTok</Button>
                        <Button onClick={handlePlatformClick} value="youtube" variant={platform === 'youtube' ? 'youtube' : 'outline-youtube'}>YouTube</Button>
                    </div>
                </div>

                <h2 className="instructions mb-3">Please type a username you want to edit</h2>
                
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon2"
                        onChange={handleChange}
                        value={username}
                        onFocus={() => setShowSuggestions(true)}
                        onBlur={() => setTimeout(() => setShowSuggestions(false), 100)}
                    />
          </InputGroup>
          <Dropdown show={(showSuggestions && suggestions.length >  0)|| (username && username.length >= 3)} onClick={() => setShowSuggestions(!showSuggestions)}>
            <Dropdown.Menu>
                {loading ? (
                    <Dropdown.Item className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    </Dropdown.Item>
                ) : (
                    suggestions.map((suggestion) => (
                    <Dropdown.Item href={`/profile/${suggestion._id}`} className="text-center" key={suggestion._id}>
                        {suggestion.username}
                    </Dropdown.Item>
                    ))
                )}
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </div>
      <div className="creator-list-container mt-5 creator-table">
  <h2 className="creator-list-title mb-4 creator-table">Your Creators</h2>
  <table className="table table-striped creator-table">
    <thead>
      <tr>
        <th scope="col">Username</th>
        <th scope="col">Platform</th>
        <th scope="col">Country</th>
        <th scope="col">Added</th>
        <th scope="col">Edit</th>
      </tr>
    </thead>
    <tbody>
      {creators.map((creator) => (
        <tr key={creator._id}>
          <td>{creator.username}</td>
          <td>{creator._index}</td>
          <td>{creator.country}</td>
          <td>{new Date(creator.lastEdited).toLocaleDateString()}</td>
          <td>
            <Button href={`/profile/${creator._id}`} variant="primary">
              Edit
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

    </div>
  );
}
export default Hello;



