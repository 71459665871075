import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './ProfilePage.css';
import countryArray from './util/countryArray'
import languageArray from './util/languageArray.js'
import FileUpload from './util/DragDrop'
import { SERVERURL } from '../config/VARS'
import { Container, Row, Col, Button, Table, Form, Spinner, Modal } from 'react-bootstrap';
import infludataLogo from './infludata-logo.png'; // Make sure to import the logo
import customFetch from './customFetch';



async function getUserById(id) {
    let response = await customFetch(`${SERVERURL}getUserById?objectId=${id}`, {
        headers: {
            accept: "application/json, text/plain, */*"
        },
        method: "GET",
        credentials: "include"
    })
    let data = await response.json()
    console.log(data.userArray[0])
    return data.userArray[0]
}

async function saveUserData(userData) {
    // mongo
    //console.log('userdata', userData)
    let response = await fetch(`${SERVERURL}storeManagementData`, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify(userData),
        //mode: 'no-cors'
    })
    console.log('that came', response)
    return response
}

function ProfilePage({ match }) {
    const [user, setUser] = useState({});
    const [oldUser, setOldUser] = useState({});
    const [editing, setEditing] = useState(false);
    const [filenames, setFilenames] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Add a new state for loading
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
  



    useEffect(() => {
        async function fetchData() {
            const data = await getUserById(match.params.id);
            setUser(data);
            setOldUser(data);
            setIsLoading(false); // Set loading to false after fetching data
        }
        fetchData();
    }, [match.params.id]);

    let history = useHistory()

    const handleSave = async (user, oldUser, filenames) => {
        setLoading(true);
        let userChanges = {};
    
        for (const key in user) {
            if (user[key] !== oldUser[key] || key === 'platform' || key === '_id' || key === '_index' || key === 'username') {
                userChanges[key] = user[key];
            }
            console.log('filenames', filenames)
            if (filenames && filenames.length >= 1) {
                userChanges.uploadedFiles = filenames;
            }
        }
        const response = await saveUserData(userChanges)
        console.log('resp', response)
        if (response.ok) {
            setLoading(false);
            setShowModal(true);
        }
        setEditing(false)
    }
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const availabilityLabels = {
        1: 'No availability',
        3: 'Partly available',
        5: 'Open for collaborations',
      };
    return (
        <Container className="profile-page">
        <header>
          <img src={infludataLogo} alt="Infludata Logo" className="infludata-logo" />
          <h1 className="header-title">influData Creator Management</h1>
        </header>
        {isLoading ? (
          <div className="text-center mt-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-12">
              <button className="btn btn-secondary back-button" onClick={() => history.goBack()}>Back</button>
              <h1 className="text-center">Profile: {user.username}</h1>
              <img className="profile-pic" src={user.profilePicURL} alt="profile" />
              <table className="table table-striped table-bordered table-hover">
                <tbody>
                  <tr>
                    <th scope="row">Account Type</th>
                    <td>
                      <select className="form-control" value={!user.accountType ? user.typeScore >= 0 ? 'creator' : 'brand' : user.accountType} onChange={event => setUser({ ...user, accountType: event.target.value })}>
                        <option value="creator">Creator</option>
                        <option value="brand">Brand</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Followers</th>
                    <td>{user.followers}</td>
                  </tr>
                  <tr>
                    <th scope="row">Posts</th>
                    <td>{user.posts}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{user.publicEmail}</td>
                  </tr>
                  <tr>
                    <th scope="row">Description</th>
                    <td>{user.description}</td>
                  </tr>
                  <tr>
                    <th scope="row">Country</th>
                    <td>
                      <select className="form-control" value={user.country} onChange={event => setUser({ ...user, country: event.target.value })}>
                        {countryArray.map(country => (
                          <option key={country} value={country}>{country}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">City</th>
                    <td>
                      <input type="text" className="form-control" value={user.city || ''} onChange={event => setUser({ ...user, city: event.target.value })} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Language</th>
                    <td>
                      <select className="form-control" value={user.language} onChange={event => setUser({ ...user, language: event.target.value })}>
                        {languageArray.map(language => (
                          <option key={language} value={language}>{language}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Gender</th>
                    <td>
                      <select className="form-control" value={user.gender} onChange={event => setUser({ ...user, gender: event.target.value })}>
                        {['', 'm', 'w'].map(gender => (
                          <option key={gender} value={gender}>{gender}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Management</th>
                    <td>
                    <input type="text" className="form-control" value={user.collaborationInfo?.management || ''} onChange={event => setUser({ ...user, collaborationInfo: { ...user.collaborationInfo, management: event.target.value } })} />
          </td>
        </tr>
        <tr>
          <th scope="row">WhatsApp Number</th>
          <td>
            <input type="text" className="form-control" value={user.collaborationInfo?.whatsApp || ''} onChange={event => setUser({ ...user, collaborationInfo: { ...user.collaborationInfo, whatsApp: event.target.value } })} />
          </td>
        </tr>
        <tr>
          <th scope="row">Contact Mail</th>
          <td>
            <input type="text" className="form-control" value={user.collaborationInfo?.contactMail || ''} onChange={event => setUser({ ...user, collaborationInfo: { ...user.collaborationInfo, contactMail: event.target.value } })} />
          </td>
        </tr>
        <tr>
          <th scope="row">Contact Link</th>
          <td>
            <input type="text" className="form-control" value={user.collaborationInfo?.contactLink || ''} onChange={event => setUser({ ...user, collaborationInfo: { ...user.collaborationInfo, contactLink: event.target.value } })} />
          </td>
        </tr>
        <tr>
          <th scope="row">Availability for Cooperations</th>
          <td>
            <div className="scale-container">
              {Object.entries(availabilityLabels).map(([value, label]) => (
                <React.Fragment key={value}>
                  <input
                    type="radio"
                    id={`availability-${value}`}
                    name="availability"
                    value={value}
                    checked={user.collaborationInfo?.availability === parseInt(value)}
                    onChange={(event) =>
                      setUser({
                        ...user,
                        collaborationInfo: {
                          ...user.collaborationInfo,
                          availability: parseInt(event.target.value),
                        },
                      })
                    }
                  />
                  <label htmlFor={`availability-${value}`} className="scale-label">
                    {label}
                  </label>
                </React.Fragment>
              ))}
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">Social Handles</th>
          <td>
            <div>
              TikTok: <input type="text" className="form-control" value={user.socialHandles?.tiktok || ''} onChange={e => setUser({ ...user, socialHandles: { ...user.socialHandles, tiktok: e.target.value } })} />
            </div>
            <div>
              YouTube: <input type="text" className="form-control" value={user.socialHandles?.youtube || ''} onChange={e => setUser({ ...user, socialHandles: { ...user.socialHandles, youtube: e.target.value } })} />
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row">Message to Agencies & Brands</th>
          <td>
            <textarea className="form-control" value={user.collaborationInfo?.message || ''} onChange={e => setUser({ ...user, collaborationInfo: { ...user.collaborationInfo, message: e.target.value } })}></textarea>
          </td>
        </tr>
      </tbody>
    </table>
    <FileUpload user={user} setFilenames={setFilenames} setUser={setUser}/>
    <button
        className="btn btn-primary save-button"
        onClick={() => handleSave(user, oldUser, filenames)}
      >
        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          'Save'
        )}
      </button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Changes Saved</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your changes are added to influData. Please keep in mind that it
          will take up to 10 minutes until the changes are visible and any
          further changes would overwrite the current changes.
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleCloseModal}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>

  </div>
</div>
)}
</Container>
)}
      

export default ProfilePage;
